import React, {useEffect, useMemo, useRef, useState} from 'react'
import Navbar from '../../components/Navbar/Navbar'
import {useHistory, useParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {
    Flex,
    Text,
    Box,
    Button,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Stack,
    Image,
    Button as ChakraButton,
    useDisclosure, MenuButton, MenuList, MenuItem, Menu,
} from '@chakra-ui/react'
import {
    InputLabel,
    TextareaLabel,
    Incrementer,
    ChakraSelectLabel as Select,
    ChartUserComplete, Checkbox
} from '../../components/Theme'
import {S3_BUCKET, SPECIALITIES} from '../../constants'
import styles from './styles'
import stylesCheckbox from "../CompanyEditAdmin/styles";

import {
    useCreateActivity, useGetCompany,
    useUpdateCompany,
    useUploadMultiple,
    useUser,
    useUserProgress
} from '../../core/hooks'
import Header from './Header'
import ChartCompany from '../../components/Theme/ChartCompany'
import {STATES} from "../../constants/serviceableAreas"
import {ReactComponent as Search} from "../../assets/searchWhite.svg";
import moment from "moment";
import {ReactComponent as Download} from "../../assets/download.svg";
import fileSaver from "file-saver/dist/FileSaver";
import {ReactComponent as DeleteIcon} from "../../assets/del.svg";
import {ReactComponent as DownIcon} from "../../assets/downArrow.svg";
import DeleteFileModal from "./Modals/DeleteFileModal";
import {InitialScore} from "../../constants/chartDatas";
import {ACTIVITY_ENUM} from "../../constants/activityTypes";
import {EU, REU, SU, SUPERADMIN} from "../../constants/user/userEnum";
import DoneButton from "../../components/Theme/Buttons/DoneButton";
import mainStyles from "../../styles/mainStyles";
import {COMPANY_OBJECT, EU_COMPANY_TYPE, REU_COMPANY_TYPE, SU_COMPANY_TYPE} from "../../constants/company/companyEnum";
import ServiceableAreas from "./Modals/ServiceableAreas";
import {ReactComponent as Edit_Modal} from "../../assets/edit_modal.svg";
import useCreateFile from "../../core/hooks/files/useCreateFile";
import {COMPANY} from "../../constants/fileTypes";
import httpRequest from "../../core/services/http-request";


const EditCompany = () => {
    const {user} = useUser()
    const history = useHistory()
    const params = useParams()
    const inputRef = useRef()
    const [avatar] = useState(user?.avatar ? S3_BUCKET + user.avatar : '')
    const newArray = useUserProgress(user)
    const [complete, setComplete] = useState([])
    const [companyType, setCompanyType] = useState([])
    const [score, setScore] = useState(InitialScore)
    const [fileForDelete, setFileForDelete] = useState()
    const [serviceArea, setServiceArea] = useState([])
    const {isOpen: isOpenServiceArea, onOpen: onOpenServiceArea, onClose: onCloseServiceArea} = useDisclosure()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {mutate: updateCompany, isLoading} = useUpdateCompany()
    const {newFile, isLoadingFile} = useCreateFile()
    const {company: companyResponse, refetch} = useGetCompany(params?.id)
    const {formState: {errors}, register, getValues, handleSubmit, setValue, reset, watch,} = useForm({
        defaultValues: {
            COMPANY_OBJECT
        }
    })
    const {createActivity} = useCreateActivity()
    const {upload, isLoading: isLoadingUpload, error,} = useUploadMultiple()
    const [files, setFiles] = useState([])
    const image = watch('image', '')

    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        const activityInput = {
            userName: `${user?.firstName} ${user?.lastName}`,
            userType: user?.type,
            projectTitle: user?.company?.length > 0 ? user?.company[0]?.name : user?.associatedToCompany?.name,
            actionId: user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany?.id,
            action: ACTIVITY_ENUM.UPLOAD_FILE_COMPANY,
        }
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    sortId: files.length,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )

        upload(
            filesToUpload,
            {
                onSuccess: (e) => {
                    for (let i = 0; i < e.length; i++) {
                        newFile({
                                name: e[i].data.data,
                                file: dataToPreview[0],
                                type: COMPANY,
                                collectionId: user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany?.id
                            },
                            {
                                onSuccess: () => {
                                    createActivity(activityInput)
                                }
                            })
                    }
                },
            },
        )
    }

    function downloadFile(e, key) {
        fileSaver.saveAs(S3_BUCKET + key, key);
    }

    const onSubmit = (data) => {
        const input = {
            ...data,
            eventType: user.type === SUPERADMIN ? 6 : 7,
            files: files,
            type: companyType,
            propertyPriorities: [JSON.stringify(score)],
        }
        updateCompany(
            input,
            {
                onSuccess: () => {
                    history.push('/profile')
                    window.location.reload()
                },
            },
        )
    }


    useMemo(() => {
        if (!companyResponse) return
        setScore(JSON.parse(companyResponse?.data?.data?.propertyPriorities[0]))
        reset({...companyResponse?.data?.data})
        setCompanyType(companyResponse?.data?.data?.type)
        setServiceArea(JSON.parse(companyResponse?.data?.data?.serviceAreas[0]))
        if (companyResponse?.data?.data?.files?.length > 0) {
            setFiles(companyResponse?.data?.data?.files)
        }
    }, [companyResponse])

    useEffect(() => {
        if (!user) return
        setComplete(newArray)
    }, [user])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DeleteFileModal
                refetch={refetch}
                fileId={fileForDelete}
                onClose={onClose}
                isOpen={isOpen}/>
            <Navbar
                minW="1240px"
                title='Edit Company'>
                <DoneButton isLoading={isLoading}/>
            </Navbar>
            <Flex
                css={mainStyles.container}>
                <Flex
                    borderBottom="1px solid #DBDADA"
                    justifyContent="space-between"
                    alignItems="center"
                    px={12}
                    h="139px"
                    w="100%">
                    <Flex
                        alignItems="center">
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            overflow="hidden"
                            border="1px solid"
                            borderColor="grey.200"
                            borderRadius="100%"
                            width="85px"
                            height="85px">
                            {
                                avatar && (
                                    <Image
                                        src={avatar}
                                        alt="user avatar"
                                        height="100%"
                                        width="100%"
                                        objectFit="cover"/>
                                )
                            }
                        </Flex>
                        <Box
                            ml={8}>
                            <Heading
                                m="0"
                                mb="2"
                                fontSize="3xl"
                                as="h2">
                                {user?.firstName}{' '}{user?.lastName}
                            </Heading>
                        </Box>
                    </Flex>
                    <Flex
                        alignItems="center">
                        <Text
                            mr={4}
                            fontSize="xl">
                            {complete[0]}% Completed
                        </Text>
                        <ChartUserComplete
                            complete={complete}/>
                    </Flex>
                </Flex>
                <Tabs mx='-1px'>
                    <TabList css={mainStyles.tabNames}>
                        <Tab>Company</Tab>
                    </TabList>
                    <TabPanels css={mainStyles.tabPanels}>
                        <TabPanel className='tab-panel'>
                            <Stack w="full" spacing="8">
                                <HStack w="full" alignItems='start'>
                                    <Flex width='55%' alignItems='center'>
                                    <Header
                                        setValue={setValue}
                                        image={image}/>
                                    <InputLabel width="510px" mb={20} fontSize="24px !important" fontWeight="700"
                                                isInvalid={!!errors.name}
                                                {...register('name', {required: true})}/>
                                    </Flex>
                                    <Flex width='45%' alignItems='center' pt={2}>
                                    <Text
                                        pl={5}
                                        mr={5}
                                        whiteSpace='nowrap'
                                        fontSize='xl'>
                                        Company Type:
                                    </Text>
                                    <Menu closeOnSelect={false} width='100%'>
                                        <MenuButton
                                            width='100%'
                                            as={Flex}
                                            px={4}
                                            alignItems="center"
                                            border="1px solid"
                                            overflow="scroll"
                                            borderColor="rgba(169, 169, 169, 0.87)"
                                            borderRadius="5px"
                                            bg="white.100"
                                            justifyContent='space-between'
                                            height="50px">
                                            <Flex justifyContent='space-between' alignItems='center'>
                                                <Text
                                                    whiteSpace="nowrap"
                                                    fontSize="xl">
                                                    {companyType}
                                                </Text>
                                                <DownIcon />
                                            </Flex>
                                        </MenuButton>
                                        <MenuList
                                            inset='-10px auto auto 0'
                                            p={0}
                                            width='350px'>
                                            {
                                                user?.type === REU ? (
                                                    Object.entries(REU_COMPANY_TYPE).map(([key, value]) => (
                                                        <MenuItem
                                                            width='100%'
                                                            onClick={(e) => e.stopPropagation()}
                                                            justifyContent="space-between"
                                                            key={key}
                                                            colSpan={1}>
                                                            <Flex
                                                                justifyContent='space-between'>
                                                                <Checkbox
                                                                    mr={6}
                                                                    css={stylesCheckbox.checkboxComp}
                                                                    isChecked={companyType === value}
                                                                    onChange={() => setCompanyType(value)}
                                                                    name={value}
                                                                    iconColor="black"
                                                                    colorScheme="whiteAlpha">
                                                                </Checkbox>
                                                                <Text
                                                                    fontSize='18px'>
                                                                    {value}
                                                                </Text>
                                                            </Flex>
                                                        </MenuItem>
                                                    ))
                                                ) : user?.type === SU ? (
                                                    Object.entries(SU_COMPANY_TYPE).map(([key, value]) => (
                                                        <MenuItem
                                                            justifyContent="space-between"
                                                            key={key}
                                                            onClick={(e) => e.stopPropagation()}
                                                            colSpan={1}>
                                                            <Flex
                                                                justifyContent='space-between'>
                                                                <Checkbox
                                                                    mr={6}
                                                                    css={stylesCheckbox.checkboxComp}
                                                                    isChecked={companyType === value}
                                                                    onChange={() => setCompanyType(value)}
                                                                    name={value}
                                                                    iconColor="black"
                                                                    colorScheme="whiteAlpha">
                                                                </Checkbox>
                                                                <Text
                                                                    fontSize='18px'>
                                                                    {value}
                                                                </Text>
                                                            </Flex>
                                                        </MenuItem>
                                                    ))
                                                ) : user?.type === EU ? (
                                                        Object.entries(EU_COMPANY_TYPE).map(([key, value]) => (
                                                            <MenuItem
                                                                onClick={(e) => e.stopPropagation()}
                                                                justifyContent="space-between"
                                                                key={key}
                                                                colSpan={1}>
                                                                <Flex
                                                                    justifyContent='space-between'>
                                                                    <Checkbox
                                                                        mr={6}
                                                                        css={stylesCheckbox.checkboxComp}
                                                                        isChecked={companyType === value}
                                                                        onChange={() => setCompanyType(value)}
                                                                        name={value}
                                                                        iconColor="black"
                                                                        colorScheme="whiteAlpha">
                                                                    </Checkbox>
                                                                    <Text
                                                                        fontSize='18px'>
                                                                        {value}
                                                                    </Text>
                                                                </Flex>
                                                            </MenuItem>
                                                        ))
                                                    ) :
                                                    <MenuItem
                                                        onClick={(e) => e.stopPropagation()}
                                                        justifyContent="space-between"
                                                        colSpan={1}>
                                                        <Flex
                                                            justifyContent='space-between'>
                                                            <Checkbox
                                                                mr={6}
                                                                css={stylesCheckbox.checkboxComp}
                                                                isChecked={companyType === 'Admin'}
                                                                onChange={() => setCompanyType('Admin')}
                                                                name='Admin'
                                                                iconColor="black"
                                                                colorScheme="whiteAlpha">
                                                            </Checkbox>
                                                            <Text
                                                                fontSize='18px'>
                                                                Admin
                                                            </Text>
                                                        </Flex>
                                                    </MenuItem>
                                            }
                                        </MenuList>
                                    </Menu>
                                    </Flex>
                                </HStack>
                                <Divider my="4.25rem" borderColor="#DADADA" border="3px solid"
                                         orientation="horizontal"/>
                                <HStack w="full" spacing="6">
                                    <InputLabel isInvalid={!!errors.address} {...register('address', {required: true})}
                                                label="Address:"/>
                                    <InputLabel {...register('address2')} ml={7} label="Address Line 2:"/>
                                </HStack>
                                <HStack w="full" spacing="6" alignItems="center" justifyContent="space-between">
                                    <HStack spacing="6" alignItems="flex-end" flex="1">
                                        <InputLabel isInvalid={!!errors.city} {...register('city', {required: true})}
                                                    label="City:"/>
                                        <Select isInvalid={!!errors.state} {...register('state', {required: true})}
                                                label="State:" maxW="100px">
                                            {STATES.map((item) => (<option key={item} value={item}>{item}</option>))}
                                        </Select>
                                        <InputLabel isInvalid={!!errors.zipCode}
                                                    type="number" {...register('zipCode', {required: true})}
                                                    label="Zip Code:"/>
                                    </HStack>
                                    <Box flex="1">
                                        <InputLabel disabled {...register('country')} label="Country:"/>
                                    </Box>
                                </HStack>
                                <Flex w="100%">
                                    <InputLabel disabled label="Associated Users:"/>
                                    <InputLabel {...register('website')} ml={7} label="Website:"/>
                                </Flex>
                            </Stack>
                            <TextareaLabel {...register('notes')} label="Notes:"/>
                            <Divider my="2.25rem" borderColor="#DADADA" border="3px solid" orientation="horizontal"/>
                            <Heading mb={10} fontSize="xl" as="h2">
                                {user?.type === 'Reu' ? ('Property Types:') : ('Serviceable Property Types:')}
                            </Heading>
                            <Flex pb="60px" alignItems="center" justifyContent="space-between">
                                <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                                    {SPECIALITIES.map((interest) => (
                                        <GridItem key={interest.title} colSpan={1}>
                                            <Incrementer
                                                score={score}
                                                setScore={setScore}
                                                title={interest.title}
                                                color={interest.active}/>
                                        </GridItem>
                                    ))}
                                </Grid>
                                <ChartCompany score={score}/>
                            </Flex>
                            <Divider mb="3.25rem" borderColor="#DADADA" border="3px solid"
                                     display={user.type === EU ? 'flex' : 'none'} orientation="horizontal"/>
                            <Box
                                display={user.type === EU ? 'block' : 'none'}>
                                <Text
                                    as="h2"
                                    fontWeight="600"
                                    mb={10}
                                    fontSize="18px">
                                    Serviceable Areas:
                                </Text>
                            </Box>
                            <Text
                                display={user.type === EU ? 'block' : 'none'}
                                color="#2C628D">
                                Serviceable Areas - States:
                            </Text>
                            <Flex
                                display={user.type === EU ? 'flex' : 'none'}
                                w="100%"
                                border="1px solid #C4C4C4"
                                borderRadius="5px"
                                justifyContent="space-between"
                                p="10px"
                                mb={20}>
                                <Text
                                    fontWeight='400'
                                    fontSize='18px'>
                                    {(() => {
                                        if (serviceArea.length > 0) {
                                            let a = serviceArea?.map((item) => item.name + '(' + item.child.filter((i) => i.value === true).map((e) => e.name) + ')-')
                                            let res = a[a.length - 1].slice(0, -1)
                                            a.pop()
                                            a.push(res)
                                            return a.toString().replace(/(.{100})..+/, "$1…")
                                        }
                                    })()}
                                </Text>
                                <ServiceableAreas
                                    serviceArea={serviceArea}
                                    setServiceArea={setServiceArea}
                                    isOpen={isOpenServiceArea}
                                    onClose={onCloseServiceArea}/>
                                <Button
                                    onClick={onOpenServiceArea}
                                    leftIcon={<Edit_Modal/>}
                                    w="60px"
                                    height="22px"
                                    variant="primary"
                                    backgroundColor="#FFE68D">
                                    Edit
                                </Button>
                            </Flex>
                            <Divider my={20} borderColor="#DADADA" border="3px solid" orientation="horizontal"/>
                            <Flex width='full' mb={10} alignItems='center' justifyContent='space-between'>
                                <Text fontWeight='600' fontSize='18px'>Uploaded Files</Text>
                                <Text width='170px' fontWeight='600' fontSize='18px'>Date</Text>
                                <Box maxW='254px'>
                                    <input onChange={handleImage} type="file" multiple style={{display: 'none'}}
                                           ref={inputRef}/>
                                    <ChakraButton fontSize='12px' leftIcon={<Search width='14px'/>}
                                                  onClick={() => inputRef.current.click()} minW="160px" maxH='30px'
                                                  bg='#153046'>
                                        Browse Files
                                    </ChakraButton>
                                </Box>
                            </Flex>
                            {files?.map((value) => (
                                <Flex justifyContent='space-between' width='full' position='relative' cursor='pointer'
                                      css={styles.file} key={value?.file?.files?.key}>
                                    <Flex p={0} alignItems='center' justifyContent='center' position='absolute'
                                          bg='#FB7575' left='-8px' top='-10px' cursor='pointer' borderRadius='5px'
                                          width="20px" height="20px"
                                          onClick={() => {
                                              setFileForDelete(value.id)
                                              onOpen()
                                          }}>
                                        <DeleteIcon width='10px' height='10px' fill='black'/>
                                    </Flex>
                                    <Text width='40%'
                                          fontWeight="500">{value?.file?.name?.replace(/(.{30})..+/, "$1…")}</Text>
                                    <Text width='20%' color='#A9A9A9'
                                          fontWeight="700">{moment(value?.file?.createdAt?.date).format('MMM DD, YYYY')}</Text>
                                    <Flex justifyContent='end' width='40%'>
                                        <Button onClick={e => downloadFile(e, value?.file?.name)} mr={8}
                                                leftIcon={<Download/>} bg="#2C628D" color="white" height="30px"
                                                fontSize="12px">
                                            Download
                                        </Button>
                                    </Flex>
                                </Flex>
                            ))
                            }
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </form>
    )
}

export default EditCompany
