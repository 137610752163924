import {useEffect,} from 'react'
import {Box, Flex, Heading} from '@chakra-ui/react'
import Area from "./Area";

const LuminsTotal = ({proposal,  setLuminaire, luminaire}) => {

    useEffect(() => {
        if (!proposal) return
        const lumins = []
        for (let i = 0; i < proposal?.proposal?.length; i++) {
            for (let j = 0; j < proposal.proposal[i].luminaires?.length; j++) {
                if (proposal.proposal[i].luminaires[j]?.lampRecomendedAirtable?.length > 0) {
                    lumins.push(proposal.proposal[i].luminaires[j])
                }
                if (proposal.proposal[i].luminaires[j]?.fixtureRecomendedAirtable?.length > 0) {
                    lumins.push(proposal.proposal[i].luminaires[j])
                }
            }
        }
        setLuminaire(lumins)
    }, [proposal])

    return (
        <>
            <Flex mt={10} justifyContent='space-around' mb={4}>
                <Box w='48%' borderBottomWidth="1px" borderColor="grey.200">
                    <Heading m={0} pb={2} color="#757575" textAlign='center' fontSize="xl">
                        EXISTING COMPONENTS
                    </Heading>
                </Box>
                <Box w='48%' borderBottomWidth="1px" borderColor="#8FCF74">
                    <Heading m={0} color="#428027" textAlign='center' fontSize="xl">
                        RECOMMENDED COMPONENTS
                    </Heading>
                </Box>
            </Flex>
            <Heading
                fontSize='18px'
                mb={3}
                mt={0}
                alignItems='center'
                w='full'
                color='#193C5B'
                fontWeight='700'>
                Totals
            </Heading>
            {luminaire?.map((item) => (
                        <Flex maxW="1240px" key={item.id} mb={2}>
                            <Box flex={1} mr={6} p={1} minH='45px' border="1px solid #C4C4C4" fontSize="12px" color='#757575'>
                                {item.qty ? '['+item.qty+']' +' ': ''}
                                {item.fixtureDescription ? item.fixtureDescription +' ': ''}
                                {item.lampPerFixture ? '('+item.lampPerFixture+')' +' ': ''}
                                {item.lampDescription ? item.lampDescription +' ': ''}
                                {item.powerSupplyDescription ? 'powered by '+item.powerSupplyDescription +' ': ''}
                                {item.controlDescription ? 'controlled by '+item.controlDescription +' ': ''}
                                {item.accessoryDescriptionA ? 'with '+item.accessoryDescriptionA +' ': ''}
                                {item.accessoryDescriptionB ? ', '+item.accessoryDescriptionB +' ': ''}
                                {item.accessoryDescriptionC ? ', '+item.accessoryDescriptionC +' ': ''}
                                {item.fixtureMountHeight ? '{'+item.fixtureMountHeight+'}' +' ': ''}
                            </Box>
                            <Box flex={1} p={1} minH='45px' border="1px solid #8FCF74" fontSize="12px" color='#428027'>
                                {item.recomendedFixtureQty ? '['+item.recomendedFixtureQty+']' +' ': ''}
                                {item.fixtureRecomendedDescription ? item.fixtureRecomendedDescription +' ': ''}
                                {item.recomendedLampQty ? '('+item.recomendedLampQty+')' +' ': ''}
                                {item.lampRecomendedDescription ? item.lampRecomendedDescription +' ': ''}
                                {item.powerSupplyRecomendedDescription ? 'powered by '+item.powerSupplyRecomendedDescription +' ': ''}
                                {item.controlRecomendedDescription ? 'controlled by '+item.controlRecomendedDescription +' ': ''}
                                {item.accessoryRecomendedDescription ? 'with '+item.accessoryRecomendedDescription +' ': ''}
                                {item.fixtureMountHeight ? '{'+item.fixtureMountHeight+'}' : ''}
                            </Box>
                        </Flex>
                    ))}
            <Box pt={7} m='auto' maxWidth='1240px'>
                {proposal?.proposal?.filter((filtered) => filtered?.luminaires?.length > 0).map((item) => (
                        <Area
                            recentProposal={item}
                            key={item.id}
                            data={item}
                            areaName={item.title}/>
                    ))}
            </Box>
        </>
    )
}

export default LuminsTotal