import {Grid, GridItem} from "@chakra-ui/react";

const Luminaires = ({luminaires}) => (
    luminaires?.length > 0 && luminaires?.map((item, index) => (
        <Grid templateColumns='repeat(7, 1fr)' key={index} px={4} border='1px solid #A9A9A9' borderRadius='6px'
              minH='64px' alignItems='center' mb={5}>
            <GridItem colSpan={3}>
                {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Description'] : JSON.parse(item.fixtureRecomendedAirtable)['FE Product Description']}
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Order Code'] : JSON.parse(item.fixtureRecomendedAirtable)['Order Code']}
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))).toLocaleString('en-US')}
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {
                    item?.lampRecomendedAirtable?.length > 0 && JSON.parse(item?.lampRecomendedAirtable)['SU PPU ($) Custom'] ? '$' + JSON.parse(item.lampRecomendedAirtable)['SU PPU ($) Custom'] : item?.fixtureRecomendedAirtable && item?.fixtureRecomendedAirtable?.length > 0 && JSON.parse(item.fixtureRecomendedAirtable)['SU PPU ($) Custom'] ? '$' + JSON.parse(item.fixtureRecomendedAirtable)['SU PPU ($) Custom'] :
                        ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 100 &&
                        ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) < 200 ?
                            (item?.lampRecomendedAirtable?.length > 0 ? '$' + (JSON.parse(item.lampRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(item.lampRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1) : '$' + (JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                            : ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 200 ?
                                (item?.lampRecomendedAirtable?.length > 0 ? '$' + (JSON.parse(item.lampRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(item.lampRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1) : '$' + (JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                                : item?.lampRecomendedAirtable?.length > 0 ? '$' + JSON.parse(item.lampRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, '') : '$' + JSON.parse(item.fixtureRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, '')
                }
            </GridItem>
            <GridItem colSpan={1} textAlign='center'>
                {
                    item?.lampRecomendedAirtable?.length > 0 && JSON.parse(item?.lampRecomendedAirtable)['SU PPU ($) Custom'] ? '$' + (JSON.parse(item?.lampRecomendedAirtable)['SU PPU ($) Custom'] * (item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        : item?.fixtureRecomendedAirtable && item?.fixtureRecomendedAirtable?.length > 0 && JSON.parse(item?.fixtureRecomendedAirtable)['SU PPU ($) Custom'] ? '$' + (JSON.parse(item?.fixtureRecomendedAirtable)['SU PPU ($) Custom'] * (item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) :
                            '$' + (Number(((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 100 &&
                            ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) < 200 ?
                                (item?.lampRecomendedAirtable?.length > 0 ? (JSON.parse(item.lampRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(item.lampRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1) : (JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 1 PPU ($)']?.replace(/[^\d.]/g, '') : 1)) :
                                ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) >= 200 ?
                                    (item?.lampRecomendedAirtable?.length > 0 ? (JSON.parse(item.lampRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(item.lampRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1) : (JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)'] ? JSON.parse(item.fixtureRecomendedAirtable)['Volume Tier 2 PPU ($)']?.replace(/[^\d.]/g, '') : 1))
                                    : item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, '') : JSON.parse(item.fixtureRecomendedAirtable)['Price Per Unit ($)']?.replace(/[^\d.]/g, ''))
                            * (item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? item.lampPerFixture : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1)))?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                }
            </GridItem>
        </Grid>
    ))
)

export default Luminaires