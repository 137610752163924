import React, {useEffect, useState} from 'react'
import { Pie } from 'react-chartjs-2'
import { Flex } from '@chakra-ui/react'

const CompanyChartFixedColors = ({ score, width, height,colors }) => {
    let [counts, setCounts] = useState([])
    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    return counts[tooltipItem[0].index].title
                },
                label(tooltipItem, newLabel) {
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}`
                },
            },
        },
        hover: {mode: null},
    }
    const data = {
        datasets: [
            {
                label: '# of Votes',
                data:  counts.map(item => item.total),
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    }

    useEffect(() => {
        if (!score) return
        let res = []
        for (let i = 0; i < score.length; i++) {
            res.push({
                total: score[i].total,
                title: score[i].name,
            })
        }
        setCounts(res)
    },[score])

    return (
        <Flex
            width={width ? width : "288px"}
            height={height ? height : "288px"}>
            <Pie
                width={1000}
                height={1000}
                options={options}
                data={data}
                redraw />
        </Flex>
    )
}

export default CompanyChartFixedColors
