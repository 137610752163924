import {
    Box,
    Flex,
    Text,
    Button,
    Modal,
    ModalOverlay,
    Checkbox,
    ModalContent,
    ModalHeader,
    ModalBody,
    Divider,
} from '@chakra-ui/react'
import {AREAS_STATES} from "../../../constants/serviceableAreas"
import React, {useCallback, useMemo, useState} from "react";
import Icon from "../../../components/Theme/Icon";
import IconButton from "../../../components/Theme/IconButton";
import CustomSelectAreas from "./CustomSelectAreas";

const ServiceableAreas = ({isOpen, onClose, onOpen, serviceArea, setServiceArea,}) => {
    const [areas, setAreas] = useState(serviceArea)

    const handleSelectAllStates = useCallback((e) => {
        const checked = e.target.checked;
        if (!checked) return setAreas([])
        setAreas((prevAreas) => {
            return AREAS_STATES.map(state => ({
                ...state,
                isClose: !checked,
                child: state.child.map(city => ({
                    ...city,
                    value: checked
                }))
            }));
        });
    }, []);

    const handleSelectState = useCallback((stateIndex, checked) => {
        setAreas((prevAreas) => {
            const updatedAreas = [...prevAreas];
            updatedAreas[stateIndex].isClose = !checked;
            updatedAreas[stateIndex].child = updatedAreas[stateIndex].child.map(city => ({
                ...city,
                value: checked
            }));
            return updatedAreas;
        });
    }, []);

    const handleCityChange = useCallback((stateIndex, cityIndex, checked) => {
        setAreas((prevAreas) => {
            const updatedAreas = [...prevAreas];
            updatedAreas[stateIndex].child[cityIndex].value = checked;
            return updatedAreas;
        });
    }, []);


    useMemo(() => {
        setAreas(serviceArea)
    },[serviceArea])

    return (
        <Box>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay/>
                <ModalContent
                    overflow="hidden"
                    borderRadius="10px"
                    minW="587px">
                    <ModalHeader
                        backgroundColor="#FFE68D"
                        height="70px"
                        textAlign="center">
                        <Text
                            fontWeight="700"
                            fontSize="24px"
                            m={0}
                            as="h2">
                            Serviceable Areas
                        </Text>
                    </ModalHeader>
                    <IconButton
                        height="unset"
                        onClick={onClose}
                        _hover={{
                            bg: 'none',
                        }}
                        _active={{
                            bg: 'none',
                        }}
                        bg="none"
                        aria-label="close button"
                        position="absolute"
                        top="1.5rem"
                        right="0.75rem"
                        icon={<Icon type="darkClose"/>}/>
                    <ModalBody
                        overflow="scroll"
                        maxH="600px">
                        <Flex
                            mt={6}
                            justifyContent="flex-end">
                            <Button
                                type="submit"
                                onClick={() => {
                                    setServiceArea(areas)
                                    onClose()
                                }}
                                w="135px"
                                h="40px"
                                backgroundColor="#8FCF74">
                                <Text
                                    fontWeight="700"
                                    fontSize="12px"
                                    ml={1}
                                    color="black"
                                    mt="2px">
                                    SAVE
                                </Text>
                            </Button>
                        </Flex>
                        <Text
                            as="h2"
                            color="#2C628D">
                            Serviceable Areas - Counties
                        </Text>
                        <Box
                            minH='450px'
                            border="1px solid"
                            borderRadius="6px 6px 0px 0px"
                            p="20px"
                            borderColor="#A9A9A9">
                            <Box
                                mb={3}>
                                <Checkbox
                                    onChange={handleSelectAllStates}>
                                    Select All Counties For All States
                                </Checkbox>
                            </Box>
                            <CustomSelectAreas
                                repeat={3}
                                value={areas}
                                options={AREAS_STATES}
                                setValue={setAreas}
                                label="Select Areas"/>
                            {
                                areas?.length > 0 && areas.map((state, stateIndex) => (
                                    <Box key={state.name}>
                                        <Flex mt={5}>
                                            <Checkbox
                                                isChecked={state.child.every(city => city.value)}
                                                onChange={(e) => handleSelectState(stateIndex, e.target.checked)}
                                            />
                                            <Text
                                                w='max-content'
                                                fontWeight="700"
                                                ml={1}
                                                fontSize="18px"
                                                cursor="pointer"
                                                onClick={() => {
                                                    setAreas(prevAreas => {
                                                        const updatedAreas = [...prevAreas];
                                                        updatedAreas[stateIndex].isClose = !updatedAreas[stateIndex].isClose;
                                                        return updatedAreas;
                                                    });
                                                }}>
                                                {state.name}
                                            </Text>
                                            <Divider
                                                mt={3}
                                                ml={5}
                                                borderColor="#000000"
                                                border="1px solid"
                                                orientation="horizontal"/>
                                        </Flex>
                                        <Flex display={state.isClose ? 'none' : 'flex'}>
                                            <Divider
                                                mt={3}
                                                orientation="vertical"
                                                height='auto'
                                                borderColor="#000000"
                                                borderLeft="2px solid"
                                            />
                                            <Flex
                                                ml={5}
                                                mt={3}
                                                flexDirection="column"
                                                justifyContent="space-between">
                                                <Checkbox
                                                    isChecked={state.child.every(city => city.value)}
                                                    onChange={(e) => handleSelectState(stateIndex, e.target.checked)}>
                                                    Select All Counties For {state.name}
                                                </Checkbox>
                                                {
                                                    state.child.map((city, cityIndex) => (
                                                        <Checkbox
                                                            key={city.name}
                                                            value={city.name}
                                                            isChecked={city.value}
                                                            onChange={(e) => handleCityChange(stateIndex, cityIndex, e.target.checked)}>
                                                            {city.name}
                                                        </Checkbox>
                                                    ))
                                                }
                                            </Flex>
                                        </Flex>
                                    </Box>
                                ))
                            }
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}
export default ServiceableAreas