import {Flex, Heading, Text, Button, Input, Grid, GridItem, Box} from "@chakra-ui/react";
import moment from "moment";
import {TextareaLabel} from "../../../../components/Theme";
import {ReactComponent as Arrow} from "../../../../assets/arrowRightUp.svg";
import {ReactComponent as Pending} from "../../../../assets/pendingIcon.svg";
import {ReactComponent as Fulfilled} from "../../../../assets/fulfiled.svg";

const ShippingMain = ({estimate, luminaires, setShippingPageStatus, setTrackId, companySu, user, onOpenDelivered, notes}) => {
    return (
        <>
            <Flex justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt="logo"/>
                <Flex flexDirection='column'>
                    <Heading m={0} textAlign='end'>
                        Shipping Overview
                    </Heading>
                    <Text textAlign='end'>
                        Reference # : {estimate?.reference}
                    </Text>
                    <Text textAlign='end'>
                        Date : {moment(estimate?.shippingDateCreated?.date).format('MM/DD/YYYY')}
                    </Text>
                </Flex>
            </Flex>
            <Flex mt={8} justifyContent='space-between' fontSize='18px'>
                <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                    <Box as='h2' m={0} mb={3} backgroundColor='#EEEEEE' width='98%' borderRadius='5px' fontSize='18px'
                         pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Sender Information:
                    </Box>
                    <Text>{companySu?.name}</Text>
                    <Text>Attn: {estimate?.userSu?.firstName} {estimate?.userSu?.lastName}</Text>
                    <Text>{companySu?.address}</Text>
                    <Text>{companySu?.address2}</Text>
                    <Text>{companySu?.city}, {companySu?.state} {companySu?.zipCode}</Text>
                    <Text>USA</Text>
                    <Text>{estimate?.userSu?.phone}</Text>
                </Flex>
                <Flex width='50%' flexDirection='column'>
                    <Box as='h2' mt={0} mb={3} backgroundColor='#EEEEEE' width='100%' borderRadius='5px' fontSize='18px'
                         pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Recipient Information:
                    </Box>
                    <Text>{user?.type === 'Su' ? 'Focus Earth' : estimate?.userReu?.company[0]?.name}</Text>
                    <Text>Attn
                        : {user?.type === 'Su' ? 'Pete Musser' : estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}</Text>
                    <Text>{user?.type === 'Su' ? '1070 Tunnel Road, Building 1, Suite 10, #202' : estimate?.report?.project?.address}</Text>
                    <Text>{user?.type === 'Su' ? '' : estimate?.report?.project?.address2}</Text>
                    <Text>{user?.type === 'Su' ? 'Asheville, NC 28805' : estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}</Text>
                    <Text>USA</Text>
                    <Text>{user?.type === 'Su' ? '(925) 323-0053' : estimate?.userReu?.phone}</Text>
                </Flex>
            </Flex>
            <Input
                readOnly
                mt={8}
                border='none'
                backgroundColor='#222222'
                width='100%'
                fontSize='18px'
                value='Shipping Information:'
                color='white'
                fontWeight='700'/>
            <Grid templateColumns='repeat(6, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>Description</GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>Part Number</GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>QTY</GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>Status</GridItem>
            </Grid>
            {
                luminaires?.map((item, index) =>
                    <Grid templateColumns='repeat(6, 1fr)' my={5} px={4} key={index} border='1px solid #A9A9A9'
                          borderRadius='6px' minH='64px' alignItems='center' mb={5}>
                        <GridItem colSpan={3}>
                            {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Description'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['FE Product Description'] : ''}
                        </GridItem>
                        <GridItem colSpan={1} textAlign='center'>
                            {item?.lampRecomendedAirtable?.length > 0 ? JSON.parse(item.lampRecomendedAirtable)['Order Code'] : item?.fixtureRecomendedAirtable?.length > 0 ? JSON.parse(item.fixtureRecomendedAirtable)['Order Code'] : ''}
                        </GridItem>
                        <GridItem colSpan={1} textAlign='center'>
                            {((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? Number(item.lampPerFixture) : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))).toLocaleString('en-US')}
                        </GridItem>
                        <GridItem colSpan={1} textAlign='center'>
                            {
                                estimate?.shippingDetails?.find(detail =>
                                    detail?.luminaires?.find(led => led.id === item.id && ((item.recomendedLampQty ? Number(item.recomendedLampQty) : (item.lampPerFixture ? Number(item.lampPerFixture) : 1)) * (item.recomendedFixtureQty ? Number(item.recomendedFixtureQty) : (item.qty ? Number(item.qty) : 1))) <= Number(led?.qtyShippingDetails) && detail.status === 2)
                                ) ? 'Delivered' : 'Pending'
                            }
                        </GridItem>
                    </Grid>
                )
            }
            <TextareaLabel
                minH='56px'
                backgroundColor='#EEEEEE !important'
                disabled
                value={notes}
                label='Notes'/>
            <Input
                backgroundColor='#FF9A7A'
                width='100%'
                fontSize='18px'
                fontWeight='700'
                readOnly
                border='none'
                value='Shipping Details'
                borderRadius='5px'
                mt={10}/>
            <Flex mt={7} mb={7} fontWeight='600'>
                <Text as='h2' fontSize='14px' mt={0} mb={0} ml={2} width='50%'>Shipping Reference #</Text>
                <Text as='h2' fontSize='14px' mt={0} mb={0} width='25%'>Created</Text>
                <Text as='h2' fontSize='14px' mt={0} mb={0} width='25%'>Tracking Link</Text>
                <Text as='h2' fontSize='14px' mt={0} mb={0} width='25%' mr={2}>Status</Text>
            </Flex>
            {
                estimate?.shippingDetails?.map((item, index) =>
                    <>
                        <Flex
                            mb={4}
                            key={index}
                            backgroundColor='#EEEEEE'
                            border='1px solid #A9A9A9'
                            borderRadius='6px'
                            minH='64px'
                            cursor='pointer'
                            alignItems='center'>
                            <Text
                                onClick={() => {
                                    setTrackId(item?.shippingReference)
                                    return setShippingPageStatus(3)
                                }}
                                ml={2} width='50%'>
                                {item?.shippingReference}
                            </Text>
                            <Text
                                onClick={() => {
                                    setTrackId(item?.shippingReference)
                                    return setShippingPageStatus(3)
                                }}
                                width='25%'>
                                {moment(item?.createdAt?.date).format('MM.DD.YYYY')}
                            </Text>
                            <Text width='25%'>
                                <Button
                                    onClick={() => {
                                        window.open((item?.senderTrackingLink && !item?.senderTrackingLink.startsWith("https://")) ? `https://${item?.senderTrackingLink}` : item?.senderTrackingLink)
                                    }}
                                    rightIcon={<Arrow/>}
                                    color='black'
                                    bg='#FFE68D'>
                                    Track
                                </Button>
                            </Text>
                            <Text
                                width='25%' mr={2}>
                                <Button
                                    _hover=''
                                    _active=''
                                    _focus=''
                                    onClick={() => {
                                        setTrackId(item?.shippingReference)
                                        onOpenDelivered()
                                    }}
                                    border={item?.status === 1 ? '1px solid #FF7F56' : '1px solid #8FCF74'}
                                    leftIcon={item?.status === 1 ? <Pending/> : <Fulfilled/>}
                                    color='black'
                                    bg='transparent'>
                                    {item?.status === 1 ? (
                                        'Pending'
                                    ) : (
                                        'Delivered'
                                    )}
                                </Button>
                            </Text>
                        </Flex>
                    </>
                )
            }
        </>
    )
}
export default ShippingMain