import {useEffect,useState} from 'react'
import Navbar from '../../components/Navbar'
import {Box, Tab, TabList, TabPanel, TabPanels, Tabs, Button, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody,} from "@chakra-ui/react";
import {ReactComponent as DownloadIcon} from "../../assets/download.svg";
import Overview from "./TabPanles/Overview";
import RealEstate from "./TabPanles/RealEstate";
import {Checkbox} from "../../components/Theme";
import styles from "./styles";
import Supplier from "./TabPanles/Supplier";
import Electrician from "./TabPanles/Electrician";
import Sales from "./TabPanles/Sales";
import Admin from "./TabPanles/Admin";
import mainStyles from "../../styles/mainStyles";
import {useGetDataMain, useGetDataWithType, useGetUsage} from "../../core/hooks";
import {DATE_ENUMS} from "../../constants/chartDatas";

const companyColors = ['#4A85B4', '#C2EF62', '#2E8A07', '#5DE2CA', '#C956FF', '#FF7F56']

const Data = () => {
    let score = {RealEstate: 0, Supplier: 0, Sales: 0, Electrician: 0, Administrator: 0,}
    const [countUsers, setCountUsers] = useState(score)
    const [countUsersTotal, setCountUsersTotal] = useState([])
    const [usersPerCompany, setUsersPerCompany] = useState([])
    const [activeCompanies, setActiveCompanies] = useState([])
    const [usersUsage, setUsersUsage] = useState([])
    const [companySquareFootage, setCompanySquareFootage] = useState([])
    const [userSalesSquareFootage, setUserSalesSquareFootage] = useState([])
    const [userSalesProposalAccepted, setUserSalesProposalAccepted] = useState([])
    const [userSalesActiveProjects, setUserSalesActiveProjects] = useState([])
    const [userSalesLedSold, setUserSalesLedSold] = useState([])
    const [companyFulfilledProposals, setCompanyFulfilledProposals] = useState([])
    const [companyPipeline, setCompanyPipeline] = useState([])
    const [electricProjectWarded, setElectricProjectWarded] = useState([])
    const [electricProjectAmountAWarded, setElectricProjectAnountAWarded] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [adminUserActiveOrders, setAdminUserActiveOrders] = useState([])
    const [adminUserCompaniesManaged, setAdminUserCompaniesManaged] = useState([])
    const [suTotalProducts, setSuTotalProducts] = useState([])
    const [usageType, setUsageType] = useState(1)
    const {mainData, isLoading} = useGetDataMain()
    const {usage, refetch: refetchUsage} = useGetUsage(usageType)
    const {dataWithType, refetch: refetchData, isLoading: isLoadingTypeData, isFetching} = useGetDataWithType(usageType)

    useEffect(() => {
        if (!mainData) return
        let dt = mainData.data.data
        setCountUsers(dt['users_count'])
        let totalCon = Object.values(dt['users_count']).reduce((acc, item) => {
            acc.count_c += parseFloat(item.count_c) || 0;
            acc.sum_total += parseFloat(item.sum_total) || 0;
            return acc;
        }, { count_c: 0, sum_total: 0 })
        setCountUsersTotal(totalCon)
        setActiveCompanies(dt['companies_usage'])
        setUsersPerCompany(dt['user_per_company'])
        setCompanySquareFootage(dt['property_size'])
        setCompanyFulfilledProposals(dt['finished_reports'])
        setCompanyPipeline(dt['max_pipeline'])
    }, [mainData])

    useEffect(() => {
        if (!usage) return
        setUsersUsage(usage.data.data)
    },[usage])

    useEffect(() => {
        if (!dataWithType) return
        let dt = dataWithType.data.data
        if (tabIndex === 3){
            setElectricProjectWarded(dt[0])
            setElectricProjectAnountAWarded(dt[1])
        } else if (tabIndex === 2){
            setSuTotalProducts(dt['total_products'])
        } else if (tabIndex === 4){
            setUserSalesSquareFootage(dt[0])
            setUserSalesProposalAccepted(dt[1])
            setUserSalesActiveProjects(dt[2])
            setUserSalesLedSold(dt[3])
        }else if (tabIndex === 5){
            setAdminUserCompaniesManaged(dt[0])
            setAdminUserActiveOrders(dt[1])
        }
    },[dataWithType])

    const setUserWithType = (e) => {
        if (e === 1) {
            setUsageType(DATE_ENUMS.REU)
        } else if (e === 0){
            setUsageType(DATE_ENUMS.MAIN)
        }else if (e === 2) {
            setUsageType(DATE_ENUMS.SU)
        } else if (e === 3) {
            setUsageType(DATE_ENUMS.EU)
        } else if (e === 4) {
            setUsageType(DATE_ENUMS.SALES)
        } else if (e === 5) {
            setUsageType(DATE_ENUMS.AU)
        }
    }

    useEffect(() => {
        refetchData()
        refetchUsage()
    },[usageType])

    return (
        <>
            <Navbar title="Data">
                <Popover>
                    <PopoverTrigger>
                        <Button leftIcon={<DownloadIcon/>} bg='#2C628D' mr={20} color='white' height='30px' minW="139px">
                            <Text ml={2} fontSize="sm">Download</Text>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent zIndex="500" p={2} w="139px" bg='#132E46' flexDirection="row" _focus={{outline: 'none'}}>
                        <PopoverArrow/>
                        <PopoverBody width='full' display="flex" p={0} color='white' flexDirection="column">
                            <Checkbox pl={2} borderRadius='4px' bg='#4A85B4' key='Real Estate' _hover='' css={styles.checkbox} name='Real Estate'>
                                <Box textAlign='center' ml={4} width='full' fontSize="14px">PDF</Box>
                            </Checkbox>
                            <Checkbox pl={2} _hover='' borderRadius='4px' bg='#8AB278' key='Suppliers' css={styles.checkbox} name='Supplier'>
                                <Box ml={5} fontSize="14px" as="span">Excel</Box>
                            </Checkbox>
                            <Checkbox pl={2} borderRadius='4px' bg='#FF7F56' key='Electricians' css={styles.checkbox} name='Electrician'>
                                <Box ml={1} fontSize="14px" as="span">Spreadsheet</Box>
                            </Checkbox>
                            <Button height='20px' color='white'>Download</Button>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Navbar>
            <Box m="auto" minW="1240px" maxW="1240px">
                <Tabs
                    index={tabIndex}
                    onChange={(index) => {
                        setTabIndex(index)
                        setUserWithType(index)
                    }}
                    bg="#F5F5F5">
                    <TabList css={mainStyles.tabNames}>
                        <Tab>Overview</Tab>
                        <Tab>Real Estate</Tab>
                        <Tab>Supplier</Tab>
                        <Tab>Electrician</Tab>
                        <Tab>Sales</Tab>
                        <Tab>Admin</Tab>
                    </TabList>
                    <TabPanels css={mainStyles.tabPanels} px='15px !important'>
                        <TabPanel>
                            <Overview
                                isLoading={isLoading}
                                companyColors={companyColors}
                                countUsersTotal={countUsersTotal}
                                countUsers={countUsers}
                                usage={usersUsage}
                                usersPerCompany={usersPerCompany}
                                activeCompanies={activeCompanies}/>
                        </TabPanel>
                        <TabPanel>
                            <RealEstate
                                companyColors={companyColors}
                                pipeline={companyPipeline}
                                proposals={companyFulfilledProposals}
                                companyFootage={companySquareFootage}
                                usage={usersUsage}
                                />
                        </TabPanel>
                        <TabPanel>
                            <Supplier
                                isFetching={isFetching}
                                totalProducts={suTotalProducts}
                                companyColors={companyColors}
                                pipeline={companyPipeline}
                                usage={usersUsage}
                                proposals={companyFulfilledProposals}
                                companyFootage={companySquareFootage}
                                countUsers={countUsers}
                                usersPerCompany={usersPerCompany}
                                activeCompanies={activeCompanies}/>
                        </TabPanel>
                        <TabPanel>
                            <Electrician
                                isLoadingTypeData={isLoadingTypeData}
                                companyColors={companyColors}
                                amount={electricProjectAmountAWarded}
                                projectsAwarded={electricProjectWarded}
                                pipeline={companyPipeline}
                                usage={usersUsage}
                                proposals={companyFulfilledProposals}
                                companyFootage={companySquareFootage}
                                countUsers={countUsers}
                                usersPerCompany={usersPerCompany}
                                activeCompanies={activeCompanies}/>
                        </TabPanel>
                        <TabPanel>
                            <Sales
                                isLoadingTypeData={isLoadingTypeData}
                                ledAmount={userSalesLedSold}
                                activeProjects={userSalesActiveProjects}
                                companyColors={companyColors}
                                salesProposal={userSalesProposalAccepted}
                                salesSquare={userSalesSquareFootage}
                                usage={usersUsage}
                                amount={electricProjectAmountAWarded}
                                projectsAwarded={electricProjectWarded}
                                pipeline={companyPipeline}
                                proposals={companyFulfilledProposals}
                                companyFootage={companySquareFootage}
                                countUsers={countUsers}
                                usersPerCompany={usersPerCompany}
                                activeCompanies={activeCompanies}/>
                        </TabPanel>
                        <TabPanel>
                            <Admin
                                isLoadingTypeData={isLoadingTypeData}
                                adminUserCompaniesManaged={adminUserCompaniesManaged}
                                activeOrders={adminUserActiveOrders}
                                companyColors={companyColors}
                                usage={usersUsage}
                                amount={electricProjectAmountAWarded}
                                pipeline={companyPipeline}
                                proposals={companyFulfilledProposals}
                                companyFootage={companySquareFootage}
                                countUsers={countUsers}
                                usersPerCompany={usersPerCompany}
                                activeCompanies={activeCompanies}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    )
}

export default Data
