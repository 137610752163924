import React from 'react'
import {Accordion as ChakraAccordion, GridItem,} from '@chakra-ui/react'
import AccordionSolo from "./AccordionSolo";

function Accordion({
                       area,
                       setArea,
                       customPricing,
                       reportId,
                       setCustomPricing,
                       recent,
                       setRecent,
                       project,
                       vale,
                       leds,
                       handleRecommended,
                   }) {
    return (
        <ChakraAccordion key={reportId} minW="900px" maxW="1240px" allowMultiple>
            {leds?.map((luminaire, index) => (
                <GridItem key={luminaire.id}>
                    <AccordionSolo
                        reportId={reportId}
                        customPricing={customPricing}
                        setCustomPricing={setCustomPricing}
                        fromAll={true}
                        project={project}
                        luminaires={area?.luminaires}
                        setRecent={setRecent}
                        recent={recent}
                        handleRecommended={handleRecommended}
                        luminaire={luminaire}
                        area={area}
                        setArea={setArea}/>
                </GridItem>
            ))}
        </ChakraAccordion>
    )
}

export default Accordion
