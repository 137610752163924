import {useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import httpRequest from "../../core/services/http-request";
import Navbar from "../../components/Navbar";
import {Flex, Button, Heading, Text, Box, useDisclosure,  Tab, TabPanels, TabPanel, Tabs, useToast, Image} from "@chakra-ui/react";
import {ReactComponent as DownloadPdf} from "../../assets/downloadIconWhite.svg";
import {ReactComponent as ShareEstimate} from "../../assets/users.svg";
import {ReactComponent as CreateOrderIcon} from "../../assets/penIcon.svg";
import {ReactComponent as ArchiveIcon} from "../../assets/archiveIcon.svg";
import {ReactComponent as ReceiptIcon} from "../../assets/bidAcceptedIcon.svg";
import {useGetEstimate, useUser} from "../../core/hooks";
import EstimateTab from "./EstimateTabs/Estimate";
import OrderTab from "./EstimateTabs/Order";
import SignatureModal from "./SignatureModal";
import {ReactComponent as ShippingIcon} from "../../assets/shippingIcon.svg";
import Shipping from "./EstimateTabs/Shipping";
import moment from "moment";
import {ReactComponent as LeftArrow} from "../../assets/leftIconShipping.svg";
import {ReactComponent as PlusIcon} from "../../assets/plusIcon.svg";
import DeliveredModal from "./DeliveredModal";
import Invoice from "./EstimateTabs/Invoice";
import Receipts from "./EstimateTabs/Receipts";
import {ReactComponent as SendMessage} from "../../assets/unfinishedIcon.svg";
import {ReactComponent as SearchIcon} from "../../assets/searchWhite.svg";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import Loading from "../../components/Loading";
import ShareEstimateModal from "../EstimateSu/shareEstimateModal";
import {ReactComponent as DoneIcon} from "../../assets/doneIcon.svg";
import {REU} from "../../constants/user/userEnum";
import {ORDER_TYPES} from "../../constants/orders/orderEnum";
import TabList from "../EstimateSu/parts/TabList";
import {ReactComponent as CloudIcon} from "../../assets/Icons/invitationSendIconBlack.svg";

const Estimate = () => {
    const date = new Date();
    let currentDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
    const refEstimate = useRef(null);
    const refOrder = useRef(null);
    const refShipping = useRef(null);
    const refInvoice = useRef(null);
    const refReceipt = useRef(null);
    const toast = useToast()
    const params = useParams()
    const history = useHistory()
    const {post, postPdf} = httpRequest()
    const {user} = useUser()
    const [estimate, setEstimate] = useState('')
    const [salesTax, setSalesTax] = useState()
    const [shipping, setShipping] = useState()
    const [depositAmount, setDepositAmount] = useState()
    const [depositDue, setDepositDue] = useState(null)
    const [remainderAmount, setRemainderAmount] = useState()
    const [remainderDue, setRemainderDue] = useState(null)
    const [total, setTotal] = useState()
    const [notes, setNotes] = useState()
    const [subTotal, setSubTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [imageURL, setImageURL] = useState(null)
    const [imageUrlAdmin, setImageUrlAdmin] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)
    const [luminaires, setLuminaires] = useState([])
    const [remainderDueDate, setRemainderDueDate] = useState(null)
    const [depositPaidDate, setDepositPaidDate] = useState(null)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [shippingDetails, setShippingDetails] = useState([])
    const {isOpen: isOpenSignature, onOpen: onOpenSignature, onClose: onCloseSignature} = useDisclosure()
    const {isOpen: isOpenDelivered, onOpen: onOpenDelivered, onClose: onCloseDelivered} = useDisclosure()
    const [trackId, setTrackId] = useState()
    const [adminRall, setAdminRall] = useState(false)
    const [shippingPageStatus, setShippingPageStatus] = useState(1)
    const [error, setError] = useState('')
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [edit, setEdit] = useState(false)
    const [createShippingAllow, setCreateShippingAllow] = useState(false)
    const [editShippingAllow, setEditShippingAllow] = useState(false)
    const {data: orderData, refetch, isLoading} = useGetEstimate({
        type: 'reu',
        id: params.id
    })
    const [companySu, setCompanySu] = useState([])
    const [companyReu, setCompanyReu] = useState([])

    const createOrder = () => {
        setLoading(true)
        let data = {
            type: 'reu',
            estimateId: estimate?.id,
            salesTax: salesTax,
            shipping: shipping,
            notes: notes,
            depositAmount: depositAmount,
            depositDue: depositDue,
            remainderDue: remainderDue,
            remainderAmount: remainderAmount,
        }
        post('create-order', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Order Created!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            setTabIndex(1)
        }).catch((e) => {
            setLoading(false)
            if (e.response.data?.errorMessage === '7001') {
                setError('Already Order')
            }
        })
    }

    const authorizeOrder = () => {
        let data = {
            estimateId: estimate?.id,
            imageUrlAdmin: imageUrlAdmin,
            date: currentDate,
        }
        setLoading(true)
        post('authorize-order-reu', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Order Authorized!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
        }).catch(() => {
            setLoading(false)
        })
    }

    const createInvoice = () => {
        setLoading(true)
        post('create-invoice', estimate?.id).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Invoice Created!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            setTabIndex(3)
        }).catch((e) => {
            if (e.response.data?.errorMessage === '7003') {
                setError('Already Invoice')
            }
            setLoading(false)
        })
    }

    const createShippingDetail = (filter) => {
        setCreateShippingAllow(true)
    }

    const editShippingDetail = (trackId, filter) => {
        setLoading(true)
        setEditShippingAllow(true)
    }

    const approveEstimate = () => {
        if (estimate.reuApproved) return
        const data = {
            estimateId: estimate?.id,
            estimateSuId: estimate?.estimateSu[0]?.id,
            date: currentDate,
            signature: imageURL,
        }
        setLoading(true)
        post('approve-estimate-reu', data).then((e) => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Order Authorized!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
        }).catch(() => {
            setLoading(false)
        })
    }

    const createReceipt = () => {
        if (!remainderDueDate || !depositPaidDate) return
        let data = {
            estId: estimate?.id,
            remDate: remainderDueDate,
            depDate: depositPaidDate,
        }
        setLoading(true)
        post('create-receipt', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Receipt Created!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            setTabIndex(4)
        }).catch(() => {
            setLoading(false)
        })

    }

    const markFulfilled = () => {
        setLoading(true)
        post('create-fulfilled', estimate?.id).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Marked as fulfilled!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
        }).catch((e) => {
            if (e.response.data?.errorMessage === '7005') {
                setError('Already Fulfilled')
            }
            setLoading(false)
        })
    }

    const archiveOrder = () => {
        setLoading(true)
        let data = {
            id: estimate?.id,
            type: 'Reu',
        }
        post('archive-order', data).then(() => {
            refetch()
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#8FCF74' icon={<SendMessage/>} title='Archived!'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            history.push('/orders/reu')
        }).catch((e) => {
            if (e.response.data?.errorMessage === '7006') {
                setError('Already Archived')
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!orderData) return
        setEstimate(orderData.data.data)
        let leds = []
        let subTotalLocal = 0
        setSalesTax(orderData.data.data?.salesTax)
        setShipping(orderData.data.data?.shipping)
        setNotes(orderData.data.data?.reuNotes)
        setDepositAmount(orderData.data.data?.depositAmount)
        setDepositDue(orderData.data.data?.depositDue)
        setRemainderDue(orderData.data.data?.remainderDue)
        setImageURL(orderData.data.data?.reuSignature)
        setDepositPaidDate(orderData.data.data?.depositPaidDate)
        setRemainderDueDate(orderData.data.data?.remainderDueDate)
        setImageUrlAdmin(orderData.data.data?.adminSignature)
        let lamps = orderData.data.data?.luminaires
        for (let j = 0; j < lamps?.length; j++) {
            let qtyGlob =  ((lamps[j]?.recomendedLampQty ? Number(lamps[j]?.recomendedLampQty) : (lamps[j]?.lampPerFixture ? lamps[j]?.lampPerFixture : 1)) *
                (lamps[j]?.recomendedFixtureQty ? Number(lamps[j]?.recomendedFixtureQty) : Number(lamps[j]?.qty)))
            if (lamps[j]?.lampRecomendedAirtable?.length > 0) {
                leds.push(lamps[j])
                lamps[j].error = false
                if (JSON.parse(lamps[j].lampRecomendedAirtable)['SU PPU ($) Custom FE']){
                    subTotalLocal += JSON.parse(lamps[j].lampRecomendedAirtable)['SU PPU ($) Custom FE'] * qtyGlob
                } else {
                    subTotalLocal += ((qtyGlob >= 100 && qtyGlob < 200 ?
                            ((JSON.parse(lamps[j].lampRecomendedAirtable)['FE PPU Vol1 ($)'] ? JSON.parse(lamps[j].lampRecomendedAirtable)['FE PPU Vol1 ($)']?.toString()?.replace(/[^\d.]/g, ''): 1))
                            : qtyGlob >= 200 ?
                                ((JSON.parse(lamps[j].lampRecomendedAirtable)['FE PPU Vol2 ($)'] ? JSON.parse(lamps[j].lampRecomendedAirtable)['FE PPU Vol2 ($)']?.toString()?.replace(/[^\d.]/g, ''): 1))
                                : lamps[j].lampRecomendedAirtable ? (JSON.parse(lamps[j].lampRecomendedAirtable)['FE PPU ($)'] || '0')?.toString()?.replace(/[^\d.]/g, '') : 0)

                        * qtyGlob)
                }
            } else if (lamps[j]?.fixtureRecomendedAirtable?.length > 0){
                leds.push(lamps[j])
                lamps[j].error = false
                if (JSON.parse(lamps[j].fixtureRecomendedAirtable)['SU PPU ($) Custom FE']){
                    subTotalLocal += JSON.parse(lamps[j].fixtureRecomendedAirtable)['SU PPU ($) Custom FE'] * qtyGlob
                } else {
                    subTotalLocal += ((qtyGlob >= 100 && qtyGlob < 200 ?
                            ((JSON.parse(lamps[j].fixtureRecomendedAirtable)['FE PPU Vol1 ($)'] ? JSON.parse(lamps[j].fixtureRecomendedAirtable)['FE PPU Vol1 ($)']?.toString()?.replace(/[^\d.]/g, ''): 1))
                            : qtyGlob >= 200 ?
                                ((JSON.parse(lamps[j].fixtureRecomendedAirtable)['FE PPU Vol2 ($)'] ? JSON.parse(lamps[j].fixtureRecomendedAirtable)['FE PPU Vol2 ($)']?.toString()?.replace(/[^\d.]/g, ''): 1))
                                : lamps[j].fixtureRecomendedAirtable ? (JSON.parse(lamps[j].fixtureRecomendedAirtable)['FE PPU ($)'] || '0')?.toString()?.replace(/[^\d.]/g, '') : 0)
                        * qtyGlob)
                }
            }
        }
        setLuminaires(leds)
        setSubTotal(subTotalLocal)
        setError('')
    },[orderData])

    const downloadPDF = (type='download') => {
        setLoadingPdf(true)
        let data = {
            luminaires: luminaires,
            salesTax: salesTax?.toLocaleString('en-US') ?? 0,
            rebates: 0,
            reference: estimate?.reference,
            date: moment(estimate?.createdAt?.date).format('MM/DD/YYYY'),
            notes: notes,
            shipping: shipping?.toLocaleString('en-US')  ?? 0,
            total: total?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})  ?? 0,
            subTotal: subTotal?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})  ?? 0,
            depositAmount: depositAmount?.toLocaleString('en-US')  ?? 0,
            depositDue: depositDue ? depositDue : 0,
            depositPaidDate: depositPaidDate  ? depositPaidDate : 0,
            remainderAmount: remainderAmount?.toLocaleString('en-US')  ?? 0,
            remainderDueDate: remainderDueDate ? remainderDueDate : 0,
            remainderDue: remainderDue ? remainderDue : 0,
            companyName: estimate?.userReu?.company?.length > 0 ? estimate?.userReu?.company[0]?.name : estimate?.userReu?.associatedToCompany?.name,
            attn: estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName,
            address: estimate?.report?.project?.address,
            address2: estimate?.report?.project?.address2,
            city: estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode,
            phone: estimate?.userReu?.phone,
            adminSignature: imageUrlAdmin,
            adminName: estimate?.userAdminConsultant ? estimate?.userAdminConsultant?.firstName + ' ' + estimate?.userAdminConsultant?.lastName : null,
            adminSignatureDate: estimate?.adminSignatureDate ? estimate?.adminSignatureDate : currentDate,
            userSignature: imageURL,
            userName: estimate?.userReu ? estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName : null,
            userSignatureDate: estimate?.reuSignatureDate ? estimate?.reuSignatureDate : currentDate,
            orderType: REU,
            companySu: companySu,
            companyReu: companyReu,
            estimateId: estimate?.id,
            orderDocumentType: ORDER_TYPES[tabIndex],
        }
        postPdf('download-estimate', data).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            setLoadingPdf(false)
            if (type.type === 'share'){
                const fileType = 'pdf'
                const name = `order${new Date().getTime() * Math.random()}.${fileType}`
                const formData = new FormData()
                formData.append("file", blob);
                formData.append("name", name);
                formData.append("type", fileType);

                return post('upload-file', formData).then((e) => {
                    let req = {
                        firstName: type.firstName,
                        lastName: type.lastName,
                        email: type.email,
                        fileName: e.data.data,
                        type: ORDER_TYPES[tabIndex] === 1 ? 'Estimate' : ORDER_TYPES[tabIndex] === 2 ? 'Order' : ORDER_TYPES[tabIndex] === 4 ? 'Invoice' : ORDER_TYPES[tabIndex] === 5 ? 'Receipt' : 'Shipping',
                        reference: estimate?.reference,
                    }
                    post('share-estimate-email', req).then(() => {
                        toast({
                            render: () => (
                                <ToastComponent bg='#FFE68D' icon={<CloudIcon/>} title='Shared Success!'/>
                            ),
                            duration: 4000,
                            isClosable: true,
                            position: "top-right"
                        })
                        onClose()
                    })
                })
            }else {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = ORDER_TYPES[tabIndex] === 1 ? `Estimate #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf` : ORDER_TYPES[tabIndex] === 2 ? `Order #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf` : ORDER_TYPES[tabIndex] === 4 ? `Invoice #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf` : ORDER_TYPES[tabIndex] === 5 ? `Receipt #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf` : `Shipping #${estimate?.reference} ${moment(estimate?.createdAt?.date).format('MM-DD-YYYY')}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).catch(() => setLoadingPdf(false))
        setError('')
    }

    useEffect(() => {
        if (!estimate?.estimateSu) return
        if (estimate?.estimateSu[0]?.userSu?.company?.length > 0){
            setCompanySu(estimate?.estimateSu[0]?.userSu?.company[0])
        } else {
            setCompanySu(estimate?.estimateSu[0]?.userSu?.associatedToCompany)
        }
        if (estimate?.userReu?.company?.length > 0){
            setCompanyReu(estimate?.userReu?.company[0])
        } else {
            setCompanyReu(estimate?.userReu?.associatedToCompany)
        }
    },[estimate])
    useEffect(() => {
        let type = params?.type
        if (type === '2'){
            setTabIndex(1)
        } else if (type === '3'){
            setTabIndex(2)
        } else if (type === '4'){
            setTabIndex(3)
        } else if (type === '5'){
            setTabIndex(4)
        } else {
            setTabIndex(0)
        }
        if (user?.type === REU) {
            setAdminRall(false)
        } else {
            setAdminRall(true)
        }
    }, [])

    useEffect(() => {
        if (!luminaires) return
        let totalLocal = 0
        totalLocal = (salesTax ? Number(salesTax) : 0) + (shipping ? Number(shipping) : 0) + (subTotal ? Number(subTotal) : 0)
        setTotal(totalLocal)
        setRemainderAmount(Math.round(totalLocal - depositAmount))
    }, [salesTax, shipping, subTotal, depositAmount])

    if  (isLoading) return <Loading />

    return (
        <>
            <Navbar minW="1050px"
                title={`Orders/Real Estate/${
                    tabIndex === 0 ? 'Estimates' :
                        tabIndex === 1 ? 'Orders' :
                            tabIndex === 2 && shippingPageStatus !== 3 ? 'Shipping Overview' :
                                tabIndex === 2 && shippingPageStatus === 3 ? 'Shipping Detail' :
                                    tabIndex === 3 ? 'Invoices' :
                                        tabIndex === 4 ? 'Receipt' :
                                            tabIndex === 5 ? 'Fulfilled' :
                                                tabIndex === 6 ? 'Archived'
                                                    : ''
                }/#${
                    tabIndex === 2 && shippingPageStatus !== 3 ? estimate?.reference :
                        tabIndex === 2 && shippingPageStatus === 3 ? trackId
                            : estimate?.reference
                }`}/>
            <ShareEstimateModal
                loading={loadingPdf}
                shareEstimate={downloadPDF}
                tabIndex={tabIndex}
                refEstimate={refEstimate}
                refOrder={refOrder}
                refShipping={refShipping}
                refInvoice={refInvoice}
                refReceipt={refReceipt}
                estimate={estimate}
                isOpen={isOpen}
                onClose={onClose}/>
            <SignatureModal
                adminRall={adminRall}
                imageURL={imageURL}
                imageUrlAdmin={imageUrlAdmin}
                setImageUrlAdmin={setImageUrlAdmin}
                setImageURL={setImageURL}
                isOpenSignature={isOpenSignature}
                onCloseSignature={onCloseSignature}/>
            <DeliveredModal
                getEstimate={refetch}
                trackId={trackId}
                setShippingPageStatus={setShippingPageStatus}
                onCloseDelivered={onCloseDelivered}
                isOpenDelivered={isOpenDelivered}/>
            <Box display={tabIndex === 5 || tabIndex === 6 ? 'none' : 'block'} m="auto" minW="1240px" maxW="1240px" border="1px solid" borderColor="#DBDADA" bg="#f5f5f5" w="100%" p={8}>
                <Flex justifyContent='space-between'>
                    <Flex flexDirection='column'>
                        <Heading mt={0} mb={3}>
                            {
                                tabIndex === 0 ? (
                                    'Estimate #' + estimate?.reference
                                ) : tabIndex === 1 ? (
                                    'Order #' + estimate?.reference
                                ) : tabIndex === 2 && shippingPageStatus !== 3 ? (
                                    'Shipping Overview #' + estimate?.reference
                                ) : tabIndex === 2 && shippingPageStatus === 3 ? (
                                    'Shipping Detail #' + trackId
                                ) : tabIndex === 3 ? (
                                    'Invoice #' + estimate?.reference
                                ) : tabIndex === 4 ? (
                                    'Receipt #' + estimate?.reference
                                ) : ('')
                            }
                        </Heading>
                        <Flex>
                            <Text fontWeight='700' fontSize='14px'>Proposal :</Text>
                            <Text fontSize='14px'>&nbsp;{estimate?.report?.proposalName}</Text>
                        </Flex>
                        <Flex>
                            <Text fontWeight='700' fontSize='14px'>Date :</Text>
                            <Text fontSize='14px'>
                                &nbsp;{
                                tabIndex === 0 ? (
                                    moment(estimate?.createdAt?.date).format('MM/DD/YYYY')
                                ) : tabIndex === 1 ? (
                                    moment(estimate?.orderDateCreated?.date).format('MM/DD/YYYY')
                                ) : tabIndex === 2 ? (
                                    moment(estimate?.shippingDateCreated?.date).format('MM/DD/YYYY')
                                ) : tabIndex === 3 ? (
                                    moment(estimate?.invoiceDateCreated?.date).format('MM/DD/YYYY')
                                ) : tabIndex === 4 ? (
                                    moment(estimate?.receiptDateCreated?.date).format('MM/DD/YYYY')
                                ) : ''
                            }
                            </Text>
                        </Flex>
                        <Flex>
                            <Text fontWeight='700' fontSize='14px'>Generated By :</Text>
                            <Text fontSize='14px'>&nbsp;{estimate?.userAdminConsultant?.firstName + ' ' + estimate?.userAdminConsultant?.lastName}</Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection='column'>
                        <Flex>
                            <Button mr={4}
                                leftIcon={
                                    tabIndex === 3 || tabIndex === 4 ? <SearchIcon/> :
                                        shippingPageStatus === 3 && tabIndex === 2 ? <LeftArrow/> : <PlusIcon/>}
                                onClick={() => {
                                    if (estimate?.archived) return
                                    if (shippingPageStatus === 3) {
                                        setShippingPageStatus(1)
                                        setTrackId(null)
                                    } else {
                                        setShippingPageStatus(2)
                                    }
                                }}
                                display={
                                    user?.type === REU && shippingPageStatus === 1 ? 'none' :
                                        tabIndex === 2 && shippingPageStatus !== 1 && !edit ? 'flex' :
                                            edit ? 'none' :
                                                'none'
                                }
                                color={(shippingPageStatus === 3 && tabIndex === 2) || tabIndex === 3 || tabIndex === 4 ? 'white' : 'black'}
                                isLoading={loading}
                                alignItems='center'
                                backgroundColor={
                                    tabIndex === 3 || tabIndex === 4 ? '#2F68D9' :
                                        shippingPageStatus === 3 ? '#353535' : '#FF9A7A'}
                                width='150px'>
                                {
                                    shippingPageStatus === 3 && tabIndex === 2 && (
                                        'Shipping Overview'
                                    )
                                }
                            </Button>
                            <Button
                                isLoading={loadingPdf}
                                onClick={downloadPDF}
                                display={
                                    tabIndex === 2 && shippingPageStatus === 2 && !edit ? 'none' :
                                        edit ? 'none'
                                            : 'flex'}
                                width='150px'
                                leftIcon={<DownloadPdf/>}>
                                Download PDF
                            </Button>
                            <Button
                                display={tabIndex === 2 && shippingPageStatus === 2 && !edit ? 'flex' :
                                    edit ? 'flex' :
                                        'none'}
                                width='135px'
                                isLoading={loading}
                                leftIcon={<DoneIcon/>}
                                color='black'
                                backgroundColor='#C2EF62'
                                onClick={() => {
                                    if (estimate?.archived) return
                                    const filter = shippingDetails.filter(item => item.isChecked)
                                    if (trackId && edit) {
                                        return editShippingDetail(trackId, filter)
                                    } else {
                                        return createShippingDetail(filter)
                                    }
                                }}>
                                Done
                            </Button>
                            <Button
                                leftIcon={
                                    tabIndex === 0 || tabIndex === 2 && shippingPageStatus !== 3 ? <CreateOrderIcon/> :
                                        tabIndex === 2 && shippingPageStatus === 3 ? <ShippingIcon/> :
                                            tabIndex === 1 ? <ShippingIcon/> :
                                                tabIndex === 3 || tabIndex === 4 ? <ReceiptIcon/>
                                                    : ''
                                }
                                display={user?.type === REU || edit ? 'none' : 'flex'}
                                onClick={
                                    tabIndex === 0 && !estimate?.archived ? createOrder :
                                        tabIndex === 2 && shippingPageStatus === 3 && !estimate?.archived ? onOpenDelivered
                                            : tabIndex === 2 && shippingPageStatus === 1 && !estimate?.archived ? createInvoice :
                                                tabIndex === 3 && !estimate?.archived ? createReceipt :
                                                    tabIndex === 4 && !estimate?.archived ? markFulfilled
                                                        : ''
                                }
                                ml={4}
                                isLoading={loading}
                                color='black'
                                backgroundColor={
                                    tabIndex === 0 ? '#8FCF74' :
                                        tabIndex === 1 ? '#FF9A7A'
                                            : '#8FCF74'}
                                width='150px'>
                                {
                                    tabIndex === 1 ? (
                                        'Create Shipping'
                                    ) : tabIndex === 2 && shippingPageStatus === 1 ? (
                                        'Create Invoice'
                                    ) : tabIndex === 2 && shippingPageStatus === 3 ? (
                                        'Mark As Delivered'
                                    ) : tabIndex === 3 ? (
                                        'Create Receipt'
                                    ) : tabIndex === 4 ? (
                                        'Mark Fulfilled!'
                                    ) : ('Create Order')
                                }
                            </Button>
                        </Flex>
                        <Flex justifyContent='end'>
                            <Button
                                display={
                                    user?.type === REU ? 'none' :
                                        tabIndex === 2 && shippingPageStatus === 3 && !edit ? 'flex' : 'none'
                                }
                                mr={4}
                                onClick={() => !estimate?.archived && estimate.isShipping ? setEdit(true) : ''}
                                leftIcon={<CreateOrderIcon/>}
                                mt={3}
                                width='150px'
                                backgroundColor='#FF9A7A'
                                color='black'>
                                Edit Detail
                            </Button>
                            <Button
                                onClick={onOpen}
                                leftIcon={<ShareEstimate/>}
                                mt={3}
                                width='150px'
                                display={edit ? 'none' : 'flex'}
                                backgroundColor='#FFE68D'
                                color='black'>
                                {
                                    tabIndex === 0 ? 'Share Estimate' :
                                        tabIndex === 1 ? 'Share Order' :
                                            tabIndex === 2 ? 'Share Shipping' :
                                                tabIndex === 3 ? 'Share Invoice' :
                                                    tabIndex === 4 ? 'Share Receipt' : ''
                                }
                            </Button>
                            <Button
                                mt={3}
                                ml={4}
                                isLoading={loading}
                                onClick={archiveOrder}
                                display={user?.type === REU || edit ? 'none' : 'flex'}
                                leftIcon={<ArchiveIcon/>}
                                backgroundColor='white'
                                width='150px'
                                border='2px solid #5F5F5F'
                                color='#5F5F5F'>
                                Archive
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
                {error && (<Text mt={5} textAlign='center' color="red.100" fontSize="m">{error}</Text>)}
            </Box>
            <Tabs
                index={tabIndex}
                onChange={(index) => setTabIndex(index)}
                minW="1240px"
                m="auto"
                maxW="1240px">
                    <TabList
                        estimate={estimate}
                        tabIndex={tabIndex}
                    />
                <TabPanels bg="#F5F5F5" py={10} px={10}>
                    <TabPanel px={20}>
                        <EstimateTab
                            isLoading={isLoading}
                            refererEstimate={refEstimate}
                            user={user}
                            setRemainderDue={setRemainderDue}
                            setRemainderAmount={setRemainderAmount}
                            setDepositDue={setDepositDue}
                            setDepositAmount={setDepositAmount}
                            remainderDue={remainderDue}
                            remainderAmount={remainderAmount}
                            depositAmount={depositAmount}
                            depositDue={depositDue}
                            luminaires={luminaires}
                            estimate={estimate}
                            subTotal={subTotal}
                            total={total}
                            notes={notes}
                            setNotes={setNotes}
                            salesTax={salesTax}
                            setSalesTax={setSalesTax}
                            shipping={shipping}
                            setShipping={setShipping}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <OrderTab
                            isLoading={isLoading}
                            refererOrder={refOrder}
                            authorizeOrder={authorizeOrder}
                            loading={loading}
                            approveEstimate={approveEstimate}
                            imageUrl={imageURL}
                            imageUrlAdmin={imageUrlAdmin}
                            user={user}
                            currentDate={currentDate}
                            onOpen={onOpenSignature}
                            remainderDue={remainderDue}
                            remainderAmount={remainderAmount}
                            depositAmount={depositAmount}
                            depositDue={depositDue}
                            luminaires={luminaires}
                            estimate={estimate}
                            subTotal={subTotal}
                            total={total}
                            notes={notes}
                            salesTax={salesTax}
                            shipping={shipping}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <Shipping
                            companySu={companySu}
                            companyReu={companyReu}
                            onOpenDelivered={onOpenDelivered}
                            getEstimate={refetch}
                            setLoading={setLoading}
                            setEditShippingAllow={setEditShippingAllow}
                            editShippingAllow={editShippingAllow}
                            edit={edit}
                            setEdit={setEdit}
                            refererShipping={refShipping}
                            setTrackId={setTrackId}
                            trackId={trackId}
                            setShippingPageStatus={setShippingPageStatus}
                            shippingPageStatus={shippingPageStatus}
                            luminaires={luminaires}
                            estimate={estimate}
                            callback={(e) => {
                                setShippingDetails(e)
                            }}
                            notes={notes}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <Invoice
                            refererInvoice={refInvoice}
                            setRemainderDueDate={setRemainderDueDate}
                            setDepositPaidDate={setDepositPaidDate}
                            depositPaidDate={depositPaidDate}
                            remainderDueDate={remainderDueDate}
                            user={user}
                            remainderDue={remainderDue}
                            remainderAmount={remainderAmount}
                            depositAmount={depositAmount}
                            depositDue={depositDue}
                            luminaires={luminaires}
                            estimate={estimate}
                            subTotal={subTotal}
                            total={total}
                            notes={notes}
                            salesTax={salesTax}
                            shipping={shipping}/>
                    </TabPanel>
                    <TabPanel px={20}>
                        <Receipts
                            refererReceipt={refReceipt}
                            setRemainderDueDate={setRemainderDueDate}
                            setDepositPaidDate={setDepositPaidDate}
                            depositPaidDate={depositPaidDate}
                            remainderDueDate={remainderDueDate}
                            user={user}
                            remainderDue={remainderDue}
                            remainderAmount={remainderAmount}
                            depositAmount={depositAmount}
                            depositDue={depositDue}
                            luminaires={luminaires}
                            estimate={estimate}
                            subTotal={subTotal}
                            total={total}
                            notes={notes}
                            salesTax={salesTax}
                            shipping={shipping}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}
export default Estimate